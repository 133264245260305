import routes from '@/store/modules/routes';
import { monitoramentoEmTempoRealHabilitado } from '@/router/guards';

export default [
  {
    path: '/monitoramento-em-tempo-real',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'],
        path: '',
        component: () => import('@/views/monitoramento-em-tempo-real/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'] }]
        },
        beforeEnter: monitoramentoEmTempoRealHabilitado
      },
      {
        name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_LISTAR'],
        path: 'beacons-x-equipamentos/listar',
        component: () => import('@/views/monitoramento-em-tempo-real/Listar.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'],
              path: '/monitoramento-em-tempo-real' 
            },
            { name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_LISTAR'] }
          ]
        },
        beforeEnter: monitoramentoEmTempoRealHabilitado
      },
      {
        name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_ADICIONAR'],
        path: 'beacons-x-equipamentos/add',
        component: () => import('@/views/monitoramento-em-tempo-real/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'],
              path: '/monitoramento-em-tempo-real' 
            },
            { 
              name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_LISTAR'],
              path: '/monitoramento-em-tempo-real/beacons-x-equipamentos/listar'
            },
            { name: 'Adicionar' }
          ]
        },
        beforeEnter: monitoramentoEmTempoRealHabilitado
      },
      {
        name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_MONITORAR'],
        path: 'beacons-x-equipamentos/monitorar',
        component: () => import('@/views/monitoramento-em-tempo-real/MonitorarEquipamentos.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'],
              path: '/monitoramento-em-tempo-real' 
            },
            { name: 'Monitorar Equipamentos' }
          ]
        },
        beforeEnter: monitoramentoEmTempoRealHabilitado
      },
      {
        name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_VISUALIZAR_MAPA'],
        path: 'beacons-x-equipamentos/visualizar-mapa/:id',
        component: () => import('@/views/monitoramento-em-tempo-real/VisualizarMapa.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['MONITORAMENTO_EM_TEMPO_REAL'],
              path: '/monitoramento-em-tempo-real' 
            },
            { 
              name: routes.state.appRoutes['BEACONS_X_EQUIPAMENTOS_LISTAR'],
              path: '/monitoramento-em-tempo-real/beacons-x-equipamentos/listar'
            },
            { name: 'Visualizar Mapa' }
          ]
        },
        beforeEnter: monitoramentoEmTempoRealHabilitado
      }
    ]
  }
];
