import routes from '@/store/modules/routes';

export default [
  {
    path: '/conciliacoes',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['CONCILIACOES_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/power-ups/conciliacoes/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'] },
            { name: 'Histórico' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CONCILIACOES_ADICIONAR'],
        path: 'edit',
        component: () =>
          import('@/views/power-ups/conciliacoes/EscolherTipoConciliacao.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: 'Tipo' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CONCILIACOES_ADICIONAR_EXPRESS'],
        path: 'edit/express',
        component: () => import('@/views/power-ups/conciliacoes/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: 'Tipo', path: '/conciliacoes/edit' },
            { name: 'Express' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CONCILIACOES_ADICIONAR_CUSTOM'],
        path: 'edit/custom',
        component: () => import('@/views/power-ups/conciliacoes/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: 'Tipo', path: '/conciliacoes/edit' },
            { name: 'Custom' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CONCILIACOES_EDITAR_EXPRESS'],
        path: 'edit/express/:id',
        component: () => import('@/views/power-ups/conciliacoes/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: 'Express' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CONCILIACOES_EDITAR_CUSTOM'],
        path: 'edit/custom/:id',
        component: () => import('@/views/power-ups/conciliacoes/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: 'Custom' }
          ]
        }
      }
    ]
  }
];
