import routes from '@/store/modules/routes';

export default [
  {
    path: '/usuarios',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['USUARIOS_LISTAR'],
        path: 'listar',
        component: () => import('@/views/usuarios/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['USUARIOS_LISTAR'] }
          ]
        },
      },
      {
        name: routes.state.appRoutes['VISUALIZAR_PERFIL_USUARIO'],
        path: 'visualizar-perfil/:id',
        component: () => import('@/views/usuarios/VisualizarPerfil.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['USUARIOS_LISTAR'], path: '/usuarios/listar' },
            { name: 'Visualizar Perfil' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['USUARIOS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/usuarios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['USUARIOS_LISTAR'], path: '/usuarios/listar' },
            { name: 'Editar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['USUARIOS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/usuarios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['USUARIOS_LISTAR'], path: '/usuarios/listar' },
            { name: 'Adicionar' }
          ],
        }
      }
    ]
  }
];
