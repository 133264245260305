import Vue from 'vue';
import dayjs from 'dayjs';

Vue.filter('parseToDateTimeBR', (str) => {
  if (!str) str = '-';
  return str && str.length >= 10 ? dayjs(str).format('DD/MM/YYYY HH:mm') : str;
});

Vue.filter('parseToDateBR', (str) => {
  return str && str.length >= 10 ? dayjs(str).format('DD/MM/YYYY') : str;
});

Vue.filter('parseToYear', (str) => {
  return str && str.length >= 10 ? dayjs(str).format('YYYY') : str;
});

Vue.filter('parseNumberToIntegerBR', (num) => {
  return isNaN(parseInt(num)) ? num : parseInt(num).toLocaleString('pt-BR');
});

const parseNumberToFloatBR = (num) => {
  return isNaN(parseFloat(num)) ? num : parseFloat(num).toLocaleString('pt-BR');
};
Vue.filter('parseNumberToFloatBR', parseNumberToFloatBR);

Vue.filter('toUpperCase', (str) => {
  return str ? str.toUpperCase() : str;
});

Vue.filter('toLowerCase', (str) => {
  return str ? str.toLowerCase() : str;
});

Vue.filter('formatMacAddress', (str) => {
  if (!str) return str;
  if (str.indexOf(':') >= 0) return str.toUpperCase();
  let s1 = str.substring(0, 2);
  let s2 = str.substring(2, 4);
  let s3 = str.substring(4, 6);
  let s4 = str.substring(6, 8);
  let s5 = str.substring(8, 10);
  let s6 = str.substring(10, 12);
  return `${s1}:${s2}:${s3}:${s4}:${s5}:${s6}`.toUpperCase();
});

Vue.filter('addLeadingZero', (num) => {
  return (Number(num) <= 9 ? '0' : '') + num;
});

const formatToMonth = (data) => {
  if (!data) return '-';
  const [year, month] = data.split('-');
  return `${month}/${year}`;
};
Vue.filter('formatToMonth', formatToMonth);

Vue.filter('formatDuration', (value) => {
  
  if (!value) return '00:00:00';

  let seconds = parseInt(value, 10);

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  seconds = seconds % 60;

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':');
});

Vue.filter('formatDurationTxt', (value) => {
  
  if (!value) return '';

  let seconds = parseInt(value, 10);

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  seconds = seconds % 60;

  let duration = [];

  if (hours) duration.push(`${hours} ` + (hours > 1 ? 'horas' : 'hora'));
  if (minutes) duration.push(`${minutes} ` + (minutes > 1 ? 'minutos' : 'minuto'));
  if (seconds) duration.push(`${seconds} ` + (seconds > 1 ? 'segundos' : 'segundo'));

  return duration.join(', ');
});

Vue.filter('bytesToSize', (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
});

Vue.filter('replaceUnderscoresWithSpace', (str) => {
  return str.replace(/[_]/g, ' ');
});

Vue.filter('treatDinamic', (value, type) => {
  if (value == null || value == '') return value;
  switch (type) {
    case 'inteiro':
      return parseInt(value).toLocaleString('pt-BR');
    case 'decimal':
    case 'numeric':
      return parseFloat(value).toLocaleString('pt-BR');
    case 'data':
      return value && value.length >= 10
        ? dayjs(value).format('DD/MM/YYYY')
        : value;
    default:
      return value;
  }
});

export { formatToMonth, parseNumberToFloatBR };
