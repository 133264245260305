import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/i18n';
import '@/sass/overrides.sass';

Vue.use(Vuetify);

const theme = {
  primary: "#550899",
  secondary: "#1B1B1B",
  accent: "#6D6EA8",

  error: "#df2c14",
  info: "#2B60D6",
  success: "#518E16",
  warning: "#e27602",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  },
  icons: {
    values: {
      //
    }
  }
});
