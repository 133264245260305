import DicionarioDadosAneelService from '@/services/DicionarioDadosAneelService';

const state = {
  camposTabelas: {
    ti: 'ti',
    tuc: 'tuc',
    a1: 'a1',
    a2: 'a2',
    a3: 'a3',
    a4: 'a4',
    a5: 'a5',
    a6: 'a6',
    tip_pn: 'tponnot',
    pos: 'tpos',
    tip_inst: 'tinst',
    uar: 'tuar',
    sitcont: 'tsitcont',
    tip_unid: 'tuni',
    gru_ten: 'tgruten',
    pot_nom: 'tpotaprt',
    fas_con: 'tfascon',
    fas_con_p: 'tfascon',
    fas_con_s: 'tfascon',
    fas_con_t: 'tfascon',
    lig_fas_p: 'tfascon',
    lig_fas_s: 'tfascon',
    lig_fas_t: 'tfascon',
    cor_nom: 'tcor',
    cap_cha: 'tcor',
    rel_tp: 'treltp',
    rel_tc: 'treltc',
    clas_ten: 'tclaten',
    elo_fsv: 'tcapelfu',
    cap_elo: 'tcapelfu',
    mei_iso: 'tmeiiso',
    lig: 'tlig',
    ten_forn: 'tten',
    ten_con: 'tten',
    ten_nom: 'tten',
    ten_pri: 'tten',
    ten_sec: 'tten',
    ten_ter: 'tten',
    geom_cab: 'tcabogeom',
    form_cab: 'tcabofor',
    bit_fas_1: 'tcabobit',
    bit_fas_2: 'tcabobit',
    bit_fas_3: 'tcabobit',
    bit_neu: 'tcabobit',
    mat_fas_1: 'tcabomat',
    mat_fas_2: 'tcabomat',
    mat_fas_3: 'tcabomat',
    mat_neu: 'tcabomat',
    iso_fas_1: 'tcaboiso',
    iso_fas_2: 'tcaboiso',
    iso_fas_3: 'tcaboiso',
    iso_neu: 'tcaboiso',
    r_regul: 'tresregul',
    sub_grp: 'tsubgrp',
    sub_grp_pr: 'tsubgrp',
    sub_grp_se: 'tsubgrp',
    clas_sub: 'tclasubcla',
    gru_tar: 'tgrutar',
    sit_ativ: 'tsitati',
    are_loc: 'tare',
    tipo_lamp: 'tpip',
    tip_sist: 'tsist',
    org_ener: 'torgener',
    categ: 'tcatpt',
    tip_dia: 'tdiacrv',
    estr: 'testr',
    mat: 'testmat',
    esf: 'testesf',
    alt: 'testalt',
    conf: 'tconfig',
    tip_trafo: 'ttranf',
    aloc_perd: 'talcprd',
    tip_regu: 'tregu',
    posto: 'tpostotran',
    p_n_ope: 'tnorope'
  },
  dadosTabelas: {}
};

const getters = {
  getCamposTabelas: (state) => state.camposTabelas,
  getCampos: (state) => Object.keys(state.camposTabelas),
  getTabelas: (state) => {
    const tabelas = Object.values(state.camposTabelas);
    return [...new Set(tabelas)];
  },
  campoExiste: (state, getters) => (campo) =>
    getters.getCampos.includes(campo.toLowerCase()),
  tabelaExiste: (state, getters) => (tabela) =>
    getters.getTabelas.includes(tabela.toLowerCase()),
  getTabelaPorCampo: (state, getters) => (entidadeBdgd, campo) => {
    if (
      campo === 'pot_nom' &&
      ['uncrat', 'uncrmt', 'uncrbt'].includes(entidadeBdgd.toLowerCase())
    ) {
      return 'tpotrtv';
    }
    return getters.getCamposTabelas[campo.toLowerCase()];
  },
  getDadosTabelas: (state) => state.dadosTabelas,
  getDadosTabela: (state, getters) => (tabela) => {
    const dadosTabelas = { ...getters.getDadosTabelas };
    if (!(dadosTabelas && dadosTabelas[tabela.toLowerCase()])) return [];
    return dadosTabelas[tabela.toLowerCase()];
  }
};

const actions = {
  carregaDadosTabela({ commit }, { tabela }) {
    DicionarioDadosAneelService.getDadosTabela(tabela)
      .then(({ data }) => {
        commit('configuraDadosTabela', {
          tabela,
          valor: data
        });
      })
      .catch((err) => console.log('Erro: ', err));
  }
};

const mutations = {
  configuraDadosTabela: (state, { tabela, valor }) =>
    (state.dadosTabelas[tabela] = valor)
};

export default {
  state,
  getters,
  actions,
  mutations
};
