import routes from '@/store/modules/routes';

const listarServidores = '/geo-perdas/servidores-bancos-dados/listar';

export default [
  {
    path: '/geo-perdas/servidores-bancos-dados/',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR'],
        path: 'listar',
        component: () =>
          import('@/views/geo-perdas/servidores-bancos-dados/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: 'GeoPerdas' },
            { name: 'Servidores' },
            { name: 'Bancos de Dados' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['GEOPERDAS_SERVIDORES_BANCOS_DADOS_EDITAR'],
        path: 'edit/:id',
        component: () =>
          import('@/views/geo-perdas/servidores-bancos-dados/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: 'GeoPerdas', path: listarServidores },
            { name: 'Servidores', path: listarServidores },
            { name: 'Bancos de Dados', path: listarServidores },
            { name: 'Editar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['GEOPERDAS_SERVIDORES_BANCOS_DADOS_ADICIONAR'],
        path: 'edit',
        component: () =>
          import('@/views/geo-perdas/servidores-bancos-dados/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: 'GeoPerdas', path: listarServidores },
            { name: 'Servidores', path: listarServidores },
            { name: 'Bancos de Dados', path: listarServidores },
            { name: 'Adicionar' }
          ],
        }
      }
    ],
  }
];
