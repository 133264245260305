import routes from '@/store/modules/routes';

export default [
  {
    path: '/mapas/entidades-geo-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA'],
        path: 'visualizar-mapa',
        component: () =>
          import('@/views/entidades-geo-bdgd/VisualizarMapa.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['ENTIDADES_GEOGRAFICAS_COMPARAR_NO_MAPA'],
        path: 'comparar-versoes-no-mapa',
        component: () =>
          import('@/views/entidades-geo-bdgd/comparar-versoes-no-mapa/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['ENTIDADES_GEOGRAFICAS_COMPARAR_NO_MAPA'] }
          ]
        }
      }
    ]
  }
];
