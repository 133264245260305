import routes from '@/store/modules/routes';

export default [
  {
    path: '/mapas/layers-personalizados',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/layers-personalizados/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/layers-personalizados/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'],
              path: '/mapas/layers-personalizados/historico'
            },
            { name: 'Editar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/layers-personalizados/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'],
              path: '/mapas/layers-personalizados/historico'
            },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_EDITAR_SIMBOLOGIA'],
        path: 'edit-simbologia/:id',
        component: () =>
          import('@/views/layers-personalizados/EditSimbologia.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'],
              path: '/mapas/layers-personalizados/historico'
            },
            { name: 'Editar Simbologia' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_VISUALIZAR_MAPA'],
        path: 'visualizar-mapa/:id',
        component: () =>
          import('@/views/layers-personalizados/VisualizarMapa.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO'],
              path: '/mapas/layers-personalizados/historico'
            },
            { name: 'Visualizar Mapa' }
          ]
        }
      }
    ]
  }
];
