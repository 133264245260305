import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/extrair-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['EXTRAIR_BDGD_HISTORICO'],
        path: 'historico',
        component: () =>
          import('@/views/geracao-bdgd/extrair-bdgd/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            { name: routes.state.appRoutes['EXTRAIR_BDGD_HISTORICO'] },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['EXTRAIR_BDGD_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/geracao-bdgd/extrair-bdgd/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['EXTRAIR_BDGD_HISTORICO'],
              path: '/geracao-bdgd/extrair-bdgd/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      }
    ]
  }
];
