import routes from '@/store/modules/routes';

export default [
  {
    path: '/conciliacoes-automaticas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['CONCILIACOES_AUTOMATICAS_HISTORICO'],
        path: 'historico',
        component: () =>
          import('@/views/power-ups/conciliacoes-automaticas/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONCILIACOES_HISTORICO'], path: '/conciliacoes/historico' },
            { name: routes.state.appRoutes['CONCILIACOES_AUTOMATICAS_HISTORICO'] }
          ]
        },
      }
    ]
  }
];
