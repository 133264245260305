import routes from '@/store/modules/routes';

export default [
  {
    path: '/mapas/analises-setorizadas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/analises-setorizadas/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['ANALISES_SETORIZADAS_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/analises-setorizadas/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO'],
              path: '/mapas/analises-setorizadas/historico'
            },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['ANALISES_SETORIZADAS_EDITAR'],
        path: 'editar/:id',
        component: () => import('@/views/analises-setorizadas/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO'],
              path: '/mapas/analises-setorizadas/historico'
            },
            { name: 'Editar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['ANALISES_SETORIZADAS_VIZUALIZAR_MAPA'],
        path: 'visualizar-mapa/:id',
        component: () =>
          import('@/views/analises-setorizadas/VisualizarMapa.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            {
              name: routes.state.appRoutes['ANALISES_SETORIZADAS_HISTORICO'],
              path: '/mapas/analises-setorizadas/historico'
            },
            { name: 'Visualizar Análise Setorizada' }
          ]
        }
      }
    ]
  }
];
