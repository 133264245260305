import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/sequenciamento-eletrico',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO'],
        path: 'historico',
        component: () => import('@/views/perdas-tecnicas/sequenciamento-eletrico/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            { name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/perdas-tecnicas/sequenciamento-eletrico/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO'],
              path: '/perdas-tecnicas/sequenciamento-eletrico/historico'
            },
            { name: 'Executar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO_GERAR_MAPAS'],
        path: 'gerar-mapas/historico',
        component: () => import('@/views/perdas-tecnicas/sequenciamento-eletrico/mapas/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO'],
              path: '/perdas-tecnicas/sequenciamento-eletrico/historico'
            },
            { name: 'Gerar Mapas' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO_GERAR_MAPAS_ADICIONAR'],
        path: 'gerar-mapas/add',
        component: () => import('@/views/perdas-tecnicas/sequenciamento-eletrico/mapas/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO'],
              path: '/perdas-tecnicas/sequenciamento-eletrico/historico'
            },
            {
              name: 'Gerar Mapas',
              path: '/perdas-tecnicas/sequenciamento-eletrico/gerar-mapas/historico'
            },
            { name: 'Novo Mapa' }
          ]
        }
      }
    ]
  }
];