import routes from '@/store/modules/routes';

export default [
  {
    path: '/dados',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['RESTAURAR_BDGD_HISTORICO'],
        path: 'restaurar-bdgd/historico',
        component: () => import('@/views/dados/restaurar-bdgd/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            { name: routes.state.appRoutes['RESTAURAR_BDGD_HISTORICO'] },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['RESTAURAR_BDGD_ADICIONAR'],
        path: 'restaurar-bdgd/add',
        component: () => import('@/views/dados/restaurar-bdgd/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            { name: routes.state.appRoutes['RESTAURAR_BDGD_HISTORICO'], path: '/dados/restaurar-bdgd/historico' },
            { name: 'Adicionar' }
          ]
        },
      }
    ]
  }
];
