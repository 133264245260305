import router from '@/router/index'
import Store from '@/store';

export default {
async  changeTitle() {

    let breadCrumbList = await router.history.current.meta.breadcrumb;
    if (!breadCrumbList) return 'Norven GEO';
    
    let aux = []
    breadCrumbList.forEach(element => {
      aux.push(element.name)
    });

    document.title ='Norven GEO :: ' + Store.getters.getCompanyAcronym + ' :: ' + aux.join(" > ");
  }
};