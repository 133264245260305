import routes from '@/store/modules/routes';
import MonitoramentoEmTempoRealService from '@/services/monitoramento-em-tempo-real/MonitoramentoEmTempoRealService';

const monitoramentoEmTempoRealHabilitado = async (to, from, next) => {
    const estaHabilitado = await MonitoramentoEmTempoRealService.getEstaHabilitado();
    if (!estaHabilitado) {
      next({ name: routes.state.appRoutes['HOME'] });
    }
    next();
};

export { monitoramentoEmTempoRealHabilitado };