import routes from '@/store/modules/routes';
import store from '@/store';

export default [
  {
    path: '/empresas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['EMPRESAS_LISTAR'],
        path: 'listar',
        component: () => import('@/views/empresas/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'] }
          ]
        },
      },
      {
        name: routes.state.appRoutes['EMPRESAS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/empresas/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Editar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EMPRESAS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/empresas/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Adicionar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EMPRESAS_ENTIDADES_BDGD_OBRIGATORIAS'],
        path: 'entidades-bdgd-obrigatorias/:id',
        component: () => import('@/views/empresas/EntidadesObrigatorias.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Entidades BDGD Obrigatórias' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['AGENTES'],
        path: 'agentes/:id',
        component: () => import('@/views/empresas/Agentes.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Códigos da Distribuidora' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EXTENSAO_DE_REDE'],
        path: 'extensao-rede/:id',
        component: () => import('@/views/empresas/extensao-rede/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Extensão de Rede' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EXTENSAO_DE_REDE_ADICIONAR'],
        path: 'extensao-rede/:id/edit',
        component: () => import('@/views/empresas/extensao-rede/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: routes.state.appRoutes['EXTENSAO_DE_REDE'], path: `/empresas/extensao-rede/${store.getters.getCompanyId}`},
            { name: 'Adicionar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EXTENSAO_DE_REDE_EDITAR'],
        path: 'extensao-rede/edit/:id',
        component: () => import('@/views/empresas/extensao-rede/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: routes.state.appRoutes['EXTENSAO_DE_REDE'], path: `/empresas/extensao-rede/${store.getters.getCompanyId}`},
            { name: 'Editar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['EXTENSAO_GEOGRAFICA'],
        path: 'extensao-geografica/:id',
        component: () => import('@/views/empresas/ExtensaoGeografica.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Extensão Geográfica' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['PERIODOS_INCREMENTAIS'],
        path: 'periodos-incrementais/:id',
        component: () => import('@/views/empresas/PeriodosIncrementais.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['EMPRESAS_LISTAR'], path: '/empresas/listar' },
            { name: 'Períodos Incrementais' }
          ],
        }
      }
    ]
  }
];
