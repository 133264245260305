import Vuex from 'vuex';
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';

import appTitle from '@/store/modules/appTitle';
import company from '@/store/modules/company';
import user from '@/store/modules/user';
import tipoListaRelatorio from '@/store/modules/tipoListaRelatorio';
import tipoListaLayer from '@/store/modules/tipoListaLayer';
import tipoListaCanvas from '@/store/modules/tipoListaCanvas';
import tipoListaSetorizacao from '@/store/modules/tipoListaSetorizacao';
import tipoListaAnaliseSetorizada from '@/store/modules/tipoListaAnaliseSetorizada';
import simbologiaConfigLayer from '@/store/modules/simbologiaConfigLayer';
import configCanvas from '@/store/modules/configCanvas';
import mapFeaturesStyles from '@/store/modules/mapFeaturesStyles';
import mapConfig from '@/store/modules/mapConfig';
import dicionarioDadosAneel from '@/store/modules/dicionarioDadosAneel';
import routes from '@/store/modules/routes';

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: [
    'appTitle',
    'company',
    'user',
    'tipoListaRelatorio',
    'tipoListaLayer',
    'tipoListaCanvas',
    'tipoListaSetorizacao',
    'tipoListaAnaliseSetorizada',
    'simbologiaConfigLayer',
    'configCanvas',
    'mapFeaturesStyles',
    'mapConfig',
    'dicionarioDadosAneel',
    'routes'
  ]
});

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    appTitle,
    company,
    user,
    tipoListaRelatorio,
    tipoListaLayer,
    tipoListaCanvas,
    tipoListaSetorizacao,
    tipoListaAnaliseSetorizada,
    simbologiaConfigLayer,
    configCanvas,
    mapFeaturesStyles,
    mapConfig,
    dicionarioDadosAneel,
    routes
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: true
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    }
  },
  actions: {},
  plugins: [vuexLocal.plugin]
});
