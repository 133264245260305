import APIService from '@/services/APIService.js';

export default {
  getEstaHabilitado() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/monitoramento-em-tempo-real/esta-habilitado')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
};
