const initialState = {
  id: null,
  name: null,
  logo: null,
  acronym: null,
  capital_coordinates: null,
  mandatory_bdgd_entities: null,
  bdgds_versions: []
};

const state = {
  selectedCompany: initialState
};

const getters = {
  getSelectedCompany: (state) => state.selectedCompany,
  getCompanyId: (state) => state.selectedCompany.id,
  getCompanyName: (state) => state.selectedCompany.name,
  getCompanyLogo: (state) => state.selectedCompany.logo,
  getCompanyAcronym: (state) => state.selectedCompany.acronym,
  getCompanyCapitalCoordinates: (state) => state.selectedCompany.capital,
  getCompanyMandatoryBdgdEntities: (state) => state.selectedCompany.mandatory_bdgd_entities,
  getCompanyBdgdsVersions: (state) => state.selectedCompany.bdgds_versions,
  getCompanyLatestBdgdVersion: (state) => {
    const bdgdsVersions = state.selectedCompany.bdgds_versions;
    return bdgdsVersions[0] ? bdgdsVersions[0] : null;
  }
};

const actions = {
  async setSelectedCompany({ commit }, value) {
    commit('SET_SELECTED_COMPANY', value);
  },
  resetSelectedCompany({ commit }) {
    commit('SET_SELECTED_COMPANY', initialState);
  },
  setCompanyMandatoryBdgdEntities({ commit }, value) {
    commit('SET_COMPANY_MANDATORY_BDGD_ENTITIES', value);
  },
  setCompanyBdgdsVersions({ commit }, value) {
    commit('SET_COMPANY_BDGDS_VERSIONS', value);
  }
};

const mutations = {
  SET_SELECTED_COMPANY: (state, value) => (state.selectedCompany = value),
  SET_COMPANY_MANDATORY_BDGD_ENTITIES: (state, value) => (state.selectedCompany.mandatory_bdgd_entities = value),
  SET_COMPANY_BDGDS_VERSIONS: (state, value) => (state.selectedCompany.bdgds_versions = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
