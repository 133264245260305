import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/validacoes-samp',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['VALIDACOES_SAMP'],
        path: '',
        component: () => import('@/views/geracao-bdgd/validacoes-samp/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            { name: routes.state.appRoutes['VALIDACOES_SAMP']}
          ]
        },
      }
    ]
  }
];
