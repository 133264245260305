const state = {
  tipoListaSetorizacao: { tipo: 'padrão' }
};

const getters = {
  getTipoListaSetorizacao: (state) => state.tipoListaSetorizacao
};

const actions = {
  setTipoListaSetorizacao({ commit }, value) {
    commit('changeTipoListaSetorizacao', value);
  }
};

const mutations = {
  changeTipoListaSetorizacao: (state, value) =>
    (state.tipoListaSetorizacao = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
