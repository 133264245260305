import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/atualizacoes-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD_ESCOLHER_TIPO'],
        path: 'escolher-tipo',
        component: () =>
          import(
            '@/views/geracao-bdgd/atualizacoes-bdgd/EscolherTipoAtualizacao.vue'
          ),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: 'Escolher Tipo' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
        path: 'historico',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-bdgd/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD_ADICIONAR'],
        path: 'add',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-bdgd/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: 'Excel' },
            { name: 'Adicionar' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD_SQL_ADICIONAR'],
        path: 'sql/add',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-bdgd/sql/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: 'Massivos' },
            { name: 'Adicionar' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD_SCRIPT_MODELO_ADICIONAR'],
        path: 'script-modelo/add',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-bdgd/scripts-modelos/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: 'Scripts Modelos' },
            { name: 'Adicionar' }
          ]
        },
      }
    ]
  }
];
