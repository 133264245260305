import routes from '@/store/modules/routes';

export default [
  {
    path: '/esqueceu-senha',
    component: () => import('@/views/esqueceu-senha/Index.vue'),
    children: [
      {
        name: routes.state.appRoutes['ESQUECEU_SENHA'],
        path: '',
        component: () => import('@/views/esqueceu-senha/EsqueceuSenha.vue')
      }
    ]
  }
];
