import routes from '@/store/modules/routes';

export default [
  {
    path: '/relatorios',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['RELATORIOS_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/relatorios/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['RELATORIOS_HISTORICO'] }]
        }
      },
      {
        name: routes.state.appRoutes['RELATORIOS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/relatorios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Editar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['RELATORIOS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/relatorios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['RELATORIOS_MODELO'],
        path: 'modelo/:id',
        component: () => import('@/views/relatorios/Modelo.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['RELATORIOS_VISUALIZAR'],
        path: 'visualizar/:relatorioId',
        component: () => import('@/views/relatorios/Visualizar.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Visualizar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['RELATORIOS_CUSTOMIZADOS_ADICIONAR'],
        path: 'customizado/edit',
        component: () => import('@/views/relatorios/EditCustomizado.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Adicionar Customizado' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['RELATORIOS_CUSTOMIZADOS_EDITAR'],
        path: 'customizado/edit/:id',
        component: () => import('@/views/relatorios/EditCustomizado.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['RELATORIOS_HISTORICO'], path: '/relatorios/historico' },
            { name: 'Editar Customizado' }
          ]
        },
      }
    ]
  }
];
