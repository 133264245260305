const state = {
  simbologiaConfig: {
    simples: {
      corFeicoes: '#FF0000',
      iconeFeicoes: ''
    },
    categorizada: {
      campoParaClassificacao: null,
      iconesCoresFeicoes: {},
      iconeCorOutrasFeicoes: { icone: '', cor: '#FF0000' },
      valorMinimo: 0,
      valorMaximo: 0
    },
    graduada: {
      campoParaClassificacao: null,
      iconesCoresFeicoes: [],
      iconeCorOutrasFeicoes: '#FF0000'
    },
    mapa_calor: {
      campoParaClassificacao: null,
      raioPontos: 25,
      desfoquePontos: 15
    }
  }
};

const getters = {
  getSimbologiaConfig: (state) => state.simbologiaConfig,
  getSimbologiaConfigSimples: (state) => state.simbologiaConfig.simples,
  getSimbologiaConfigCategorizada: (state) =>
    state.simbologiaConfig.categorizada,
  getSimbologiaConfigGraduada: (state) => state.simbologiaConfig.graduada,
  getSimbologiaConfigMapaCalor: (state) => state.simbologiaConfig.mapa_calor
};

const actions = {
  resetaSimbologiaConfig({ commit }) {
    commit('changeSimbologiaConfig', {
      simples: {
        corFeicoes: '#FF0000',
        iconeFeicoes: ''
      },
      categorizada: {
        campoParaClassificacao: null,
        iconesCoresFeicoes: {},
        iconeCorOutrasFeicoes: { icone: '', cor: '#FF0000' }
      },
      graduada: {
        campoParaClassificacao: null,
        iconesCoresFeicoes: [],
        iconeCorOutrasFeicoes: { icone: '', cor: '#FF0000' },
        valorMinimo: 0,
        valorMaximo: 0
      },
      mapa_calor: {
        campoParaClassificacao: null,
        raioPontos: 25,
        desfoquePontos: 15
      }
    });
  },

  setSimbologiaConfig({ commit }, value) {
    commit('changeSimbologiaConfig', value);
  },

  setSimbologiaConfigSimples({ commit }, value) {
    commit('changeSimbologiaConfigSimples', value);
  },
  setSimbologiaConfigSimplesCorFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigSimplesCorFeicoes', value);
  },
  setSimbologiaConfigSimplesIconeFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigSimplesIconeFeicoes', value);
  },

  setSimbologiaConfigCategorizada({ commit }, value) {
    commit('changeSimbologiaConfigCategorizada', value);
  },
  setSimbologiaConfigCategorizadaCampoParaClassificacao({ commit }, value) {
    commit('changeSimbologiaConfigCategorizadaCampoParaClassificacao', value);
  },
  setSimbologiaConfigCategorizadaIconesCoresFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigCategorizadaIconesCoresFeicoes', value);
  },
  setSimbologiaConfigCategorizadaIconeCorOutrasFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigCategorizadaIconeCorOutrasFeicoes', value);
  },

  setSimbologiaConfigGraduada({ commit }, value) {
    commit('changeSimbologiaConfigGraduada', value);
  },
  setSimbologiaConfigGraduadaCampoParaClassificacao({ commit }, value) {
    commit('changeSimbologiaConfigGraduadaCampoParaClassificacao', value);
  },
  setSimbologiaConfigGraduadaIconesCoresFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigGraduadaIconesCoresFeicoes', value);
  },
  setSimbologiaConfigGraduadaIconeCorOutrasFeicoes({ commit }, value) {
    commit('changeSimbologiaConfigGraduadaIconeCorOutrasFeicoes', value);
  },
  setSimbologiaConfigGraduadaValorMinimo({ commit }, value) {
    commit('changeSimbologiaConfigGraduadaValorMinimo', value);
  },
  setSimbologiaConfigGraduadaValorMaximo({ commit }, value) {
    commit('changeSimbologiaConfigGraduadaValorMaximo', value);
  },

  setSimbologiaConfigMapaCalor({ commit }, value) {
    commit('changeSimbologiaConfigMapaCalor', value);
  },
  setSimbologiaConfigMapaCalorCampoParaClassificacao({ commit }, value) {
    commit('changeSimbologiaConfigMapaCalorCampoParaClassificacao', value);
  },
  setSimbologiaConfigMapaCalorRaioPontos({ commit }, value) {
    commit('changeSimbologiaConfigMapaCalorRaioPontos', value);
  },
  setSimbologiaConfigMapaCalorDesfoquePontos({ commit }, value) {
    commit('changeSimbologiaConfigMapaCalorDesfoquePontos', value);
  }
};

const mutations = {
  changeSimbologiaConfig: (state, value) => (state.simbologiaConfig = value),

  changeSimbologiaConfigSimples: (state, value) =>
    (state.simbologiaConfig.simples = value),
  changeSimbologiaConfigSimplesCorFeicoes: (state, value) =>
    (state.simbologiaConfig.simples.corFeicoes = value),
  changeSimbologiaConfigSimplesIconeFeicoes: (state, value) =>
    (state.simbologiaConfig.simples.iconeFeicoes = value),

  changeSimbologiaConfigCategorizada: (state, value) =>
    (state.simbologiaConfig.categorizada = value),
  changeSimbologiaConfigCategorizadaCampoParaClassificacao: (state, value) =>
    (state.simbologiaConfig.categorizada.campoParaClassificacao = value),
  changeSimbologiaConfigCategorizadaIconesCoresFeicoes: (state, value) =>
    (state.simbologiaConfig.categorizada.iconesCoresFeicoes = value),
  changeSimbologiaConfigCategorizadaIconeCorOutrasFeicoes: (state, value) =>
    (state.simbologiaConfig.categorizada.iconeCorOutrasFeicoes = value),

  changeSimbologiaConfigGraduada: (state, value) =>
    (state.simbologiaConfig.graduada = value),
  changeSimbologiaConfigGraduadaCampoParaClassificacao: (state, value) =>
    (state.simbologiaConfig.graduada.campoParaClassificacao = value),
  changeSimbologiaConfigGraduadaIconesCoresFeicoes: (state, value) =>
    (state.simbologiaConfig.graduada.iconesCoresFeicoes = value),
  changeSimbologiaConfigGraduadaIconeCorOutrasFeicoes: (state, value) =>
    (state.simbologiaConfig.graduada.iconeCorOutrasFeicoes = value),
  changeSimbologiaConfigGraduadaValorMinimo: (state, value) =>
    (state.simbologiaConfig.graduada.valorMinimo = value),
  changeSimbologiaConfigGraduadaValorMaximo: (state, value) =>
    (state.simbologiaConfig.graduada.valorMaximo = value),

  changeSimbologiaConfigMapaCalor: (state, value) =>
    (state.simbologiaConfig.mapa_calor = value),
  changeSimbologiaConfigMapaCalorCampoParaClassificacao: (state, value) =>
    (state.simbologiaConfig.mapa_calor.campoParaClassificacao = value),
  changeSimbologiaConfigMapaCalorRaioPontos: (state, value) =>
    (state.simbologiaConfig.mapa_calor.raioPontos = value),
  changeSimbologiaConfigMapaCalorDesfoquePontos: (state, value) =>
    (state.simbologiaConfig.mapa_calor.desfoquePontos = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
