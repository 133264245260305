const state = {
  tipoListaRelatorio: { tipo: 'padrão', grupo: null, modelo_id: null }
};

const getters = {
  getTipoListaRelatorio: (state) => state.tipoListaRelatorio
};

const actions = {
  setTipoListaRelatorio({ commit }, value) {
    commit('changeTipoListaRelatorio', value);
  }
};

const mutations = {
  changeTipoListaRelatorio: (state, value) => (state.tipoListaRelatorio = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
