const state = {
  tipoListaAnaliseSetorizada: { tipo: 'padrão' }
};

const getters = {
  getTipoListaAnaliseSetorizada: (state) => state.tipoListaAnaliseSetorizada
};

const actions = {
  setTipoListaAnaliseSetorizada({ commit }, value) {
    commit('changeTipoListaAnaliseSetorizada', value);
  }
};

const mutations = {
  changeTipoListaAnaliseSetorizada: (state, value) =>
    (state.tipoListaAnaliseSetorizada = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
