import routes from '@/store/modules/routes';

export default [
  {
    path: '/perfis-usuarios',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['PERFIS_USUARIOS_LISTAR'],
        path: 'listar',
        component: () => import('@/views/perfis-usuarios/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['PERFIS_USUARIOS_LISTAR'] }
          ]
        },
      },
      {
        name: routes.state.appRoutes['PERFIS_USUARIOS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/perfis-usuarios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['PERFIS_USUARIOS_LISTAR'], path: '/perfis-usuarios/listar' },
            { name: 'Editar' }
          ],
        }
      },
      {
        name: routes.state.appRoutes['PERFIS_USUARIOS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/perfis-usuarios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['PERFIS_USUARIOS_LISTAR'], path: '/perfis-usuarios/listar' },
            { name: 'Adicionar' }
          ],
        }
      }
    ]
  }
];
