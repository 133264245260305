import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/malhas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['MALHA'],
        path: 'historico',
        component: () => import('@/views/perdas-tecnicas/malhas/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            { name: routes.state.appRoutes['MALHA'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['MALHA_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/perdas-tecnicas/malhas/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['MALHA'],
              path: '/perdas-tecnicas/malhas/historico'
            },
            { name: 'Executar' }
          ]
        }
      }
    ]
  }
];