import routes from '@/store/modules/routes';

export default [
  {
    path: '/aws/servidores-rds',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['SERVIDORES_RDS_NA_AWS_LISTAR'],
        path: 'listar',
        component: () => import('@/views/aws/servidores-rds/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['SERVIDORES_RDS_NA_AWS_LISTAR'] }
          ]
        },
      }
    ]
  }
];
