import routes from '@/store/modules/routes';

export default [
  {
    path: '/sig-r-compara',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['SIGR_COMPARA_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/sig-r/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['SIGR_COMPARA_HISTORICO'] },
            { name: 'Histórico' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['SIGR_COMPARA_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/sig-r/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['SIGR_COMPARA_HISTORICO'], path: '/sig-r-compara/historico' },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['SIGR_COMPARA_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/sig-r/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            { name: routes.state.appRoutes['SIGR_COMPARA_HISTORICO'], path: '/sig-r-compara/historico' },
            { name: 'Editar' }
          ]
        }
      }
    ]
  }
];
