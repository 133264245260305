import routes from '@/store/modules/routes';

export default [
  {
    path: '/admin',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['ADMINISTRACAO'],
        path: '',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['ADMINISTRACAO'] }]
        },
      }
    ]
  }
];
