import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pt from 'vuetify/lib/locale/pt';

Vue.use(VueI18n);

const messages = {
  pt: {
    ...require('@/locales/en.json'),
    $vuetify: pt
  }
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt',
  messages
});
