import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/carregar-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['CARREGAR_BDGD_HISTORICO'],
        path: 'historico',
        component: () =>
          import('@/views/perdas-tecnicas/carregar-bdgd/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: 'Conectividade e Perdas Técnicas', path: '/perdas-tecnicas' },
            { name: 'Cargas de BDGD' },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['CARREGAR_BDGD_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/perdas-tecnicas/carregar-bdgd/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: 'Conectividade e Perdas Técnicas', path: '/perdas-tecnicas' },
            {
              name: 'Cargas de BDGD',
              path: '/perdas-tecnicas/carregar-bdgd/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      }
    ]
  }
];
