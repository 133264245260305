import routes from '@/store/modules/routes';

export default [
  {
    path: '/logs-acessos-usuarios',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['LOGS_ACESSOS_USUARIOS_LISTAR'],
        path: 'listar',
        component: () => import('@/views/logs-acessos-usuarios/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['LOGS_ACESSOS_USUARIOS_LISTAR'] }
          ]
        },
      }
    ]
  }
];
