import routes from '@/store/modules/routes';

export default [
  {
    path: '/home',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['HOME'],
        path: '',
        component: () => import('@/views/Home.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['HOME'] }]
        }
      }
    ]
  }
];
