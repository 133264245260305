import routes from '@/store/modules/routes';

export default [
  {
    path: '/consolidador-quantidades',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['CONSOLIDADOR_QUANTIDADES_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/power-ups/consolidador-quantidades/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            {
              name: routes.state.appRoutes['CONSOLIDADOR_QUANTIDADES_HISTORICO'],
              path: '/consolidador-quantidades/historico'
            },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['CONSOLIDADOR_QUANTIDADES_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/power-ups/consolidador-quantidades/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['POWER_UPS'], path: '/powerups' },
            {
              name: routes.state.appRoutes['CONSOLIDADOR_QUANTIDADES_HISTORICO'],
              path: '/consolidador-quantidades/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      }
    ]
  }
];
