import routes from '@/store/modules/routes';

export default [
  {
    path: '/perfil',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['VIZUALIZAR_PERFIL'],
        path: 'index',
        component: () => import('@/views/perfil/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['VIZUALIZAR_PERFIL'] }]
        }
      },
      {
        name: routes.state.appRoutes['LOGOUT'],
        path: 'logout',
        component: () => import('@/views/perfil/Logout.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: []
        }
      }
    ]
  }
];
