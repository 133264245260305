import admin from './admin';
import analisesSetorizadas from './analises-setorizadas';
import canvas from './canvas';
import conciliacoesAutomaticas from './conciliacoes-automaticas';
import conciliacoes from './conciliacoes';
import dados from './dados';
import empresas from './empresas';
import entidadesGeoBdgd from './entidades-geo-bdgd';
import expurgosBdgd from './expurgos-bdgd';
import gruposRelatorios from './grupos-relatorios';
import home from './home';
import importacoesDados from './importacoes-dados';
import cargasCsv from './cargas-csv';
import layersPersonalizados from './layers-personalizados';
import login from './login';
import esqueceuSenha from './esqueceu-senha';
import redefinirSenha from './redefinir-senha';
import logsAcessosUsuarios from './logs-acessos-usuarios';
import mapas from './mapas';
import perfil from './perfil';
import powerUps from './power-ups';
import relatoriosModelos from './relatorios-modelos';
import relatorios from './relatorios';
import restaurarBdgd from './restaurar-bdgd';
import setorizacoes from './setorizacoes';
import sigr from './sig-r';
import consolidadorQuantidades from './consolidador-quantidades';
import usuarios from './usuarios';
import versoesBdgds from './versoes-bdgds';
import geracaoBdgd from './geracao-bdgd';
import extrairBdgd from './geracao-bdgd/extrair-bdgd';
import importacoesBdgd from './geracao-bdgd/importacoes-bdgd';
import validacoesBdgd from './geracao-bdgd/validacoes-bdgd';
import validacoesIndqual from './geracao-bdgd/validacoes-indqual';
import validacoesSamp from './geracao-bdgd/validacoes-samp';
import atualizacoesBdgd from './geracao-bdgd/atualizacoes-bdgd';
import exportarBdgd from './geracao-bdgd/exportar-bdgd';
import entidadeBdgd from './geracao-bdgd/entidade-bdgd';
import geoPerdasServidoresBancosDados from './geo-perdas/servidores-bancos-dados';
import perdasTecnicas from './perdas-tecnicas';
import carregarBdgd from './perdas-tecnicas/carregar-bdgd';
import preencherTabelasOperacionais from './perdas-tecnicas/preencher-tabelas-operacionais';
import oracleServidoresBancosDados from './oracle/servidores-bancos-dados';
import tabelasXDiscos from './armazenamento/tabelas-x-discos';
import perfisUsuarios from './perfis-usuarios';
import awsServidoresEc2 from './aws/servidores-ec2';
import awsServidoresRds from './aws/servidores-rds';
import importacoesXML from './importacoes-xml';
import sequenciamentoEletrico from './perdas-tecnicas/sequenciamento-eletrico';
import malhas from './perdas-tecnicas/malhas';
import faseamento from './perdas-tecnicas/faseamento';
import progGeoPerdasAneel from './perdas-tecnicas/prog-geo-perdas-aneel';
import mutacaoBdgd from './mutacao-bdgd';
import monitoramentoTempoReal from './monitoramento-tempo-real';

let routes = [];
routes = routes.concat(
  admin,
  analisesSetorizadas,
  canvas,
  conciliacoesAutomaticas,
  conciliacoes,
  dados,
  empresas,
  entidadesGeoBdgd,
  expurgosBdgd,
  gruposRelatorios,
  home,
  importacoesDados,
  cargasCsv,
  layersPersonalizados,
  login,
  esqueceuSenha,
  redefinirSenha,
  logsAcessosUsuarios,
  mapas,
  perfil,
  powerUps,
  relatoriosModelos,
  relatorios,
  restaurarBdgd,
  setorizacoes,
  sigr,
  consolidadorQuantidades,
  usuarios,
  versoesBdgds,
  geracaoBdgd,
  extrairBdgd,
  importacoesBdgd,
  validacoesBdgd,
  validacoesIndqual,
  validacoesSamp,
  atualizacoesBdgd,
  exportarBdgd,
  entidadeBdgd,
  geoPerdasServidoresBancosDados,
  perdasTecnicas,
  carregarBdgd,
  preencherTabelasOperacionais,
  oracleServidoresBancosDados,
  tabelasXDiscos,
  perfisUsuarios,
  awsServidoresEc2,
  awsServidoresRds,
  importacoesXML,
  sequenciamentoEletrico,
  malhas,
  faseamento,
  progGeoPerdasAneel,
  mutacaoBdgd,
  monitoramentoTempoReal
);

routes.push({
  path: '/',
  redirect: {
    name: 'Login'
  }
});

routes.push({
  path: '*',
  component: () => import('@/views/pages/Index'),
  children: [
    {
      name: '404 Error',
      path: '',
      component: () => import('@/views/pages/Error')
    }
  ]
});

export default routes;
