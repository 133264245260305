import routes from '@/store/modules/routes';

export default [
  {
    path: '/dados/importacoes-xml',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['IMPORTACOES_INDQUAL'],
        path: 'historico',
        component: () => import('@/views/dados/importacoes-xml/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            {
              name: routes.state.appRoutes['IMPORTACOES_INDQUAL'],
              path: '/dados/importacoes-xml/historico'
            },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['IMPORTACOES_INDQUAL_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/dados/importacoes-xml/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            {
              name: routes.state.appRoutes['IMPORTACOES_INDQUAL'],
              path: '/dados/importacoes-xml/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['IMPORTACOES_INDQUAL_LOGS'],
        path: 'logs/:id',
        component: () => import('@/views/dados/importacoes-xml/Logs.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            {
              name: routes.state.appRoutes['IMPORTACOES_INDQUAL'],
              path: '/dados/importacoes-xml/historico'
            },
            { name: 'Logs' }
          ]
        },
      }
    ]
  }
];
