import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/preencher-tabelas-operacionais',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['PREENCHER_TABELAS_OPERACIONAIS_HISTORICO'],
        path: 'historico',
        component: () =>
          import('@/views/perdas-tecnicas/preencher-tabelas-operacionais/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: 'Conectividade e Perdas Técnicas', path: '/perdas-tecnicas' },
            { name: 'Etapa 17 NVAL' },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['PREENCHER_TABELAS_OPERACIONAIS_ADICIONAR'],
        path: 'nova-execucao',
        component: () => import('@/views/perdas-tecnicas/preencher-tabelas-operacionais/NovaExecucao.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: 'Conectividade e Perdas Técnicas', path: '/perdas-tecnicas' },
            {
              name: 'Etapa 17 NVAL',
              path: '/perdas-tecnicas/preencher-tabelas-operacionais/historico'
            },
            { name: 'Nova Execução' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['PREENCHER_TABELAS_OPERACIONAIS_ANDAMENTO'],
        path: 'andamento/:id',
        component: () => import('@/views/perdas-tecnicas/preencher-tabelas-operacionais/Andamento.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: 'Conectividade e Perdas Técnicas', path: '/perdas-tecnicas' },
            {
              name: 'Etapa 17 NVAL',
              path: '/perdas-tecnicas/preencher-tabelas-operacionais/historico'
            },
            { name: 'Andamento' }
          ]
        },
      }
    ]
  }
];
