import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/importacoes-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['IMPORTACOES_DE_BDGD'],
        path: 'historico',
        component: () =>
          import('@/views/geracao-bdgd/importacoes-bdgd/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            { name: routes.state.appRoutes['IMPORTACOES_DE_BDGD'] },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['IMPORTACOES_DE_BDGD_ADICIONAR'],
        path: 'add',
        component: () =>
          import('@/views/geracao-bdgd/importacoes-bdgd/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['IMPORTACOES_DE_BDGD'],
              path: '/geracao-bdgd/importacoes-bdgd/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['IMPORTACOES_DE_BDGD_LOGS'],
        path: 'logs/:id',
        component: () => import('@/views/geracao-bdgd/importacoes-bdgd/Logs'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['IMPORTACOES_DE_BDGD'],
              path: '/geracao-bdgd/importacoes-bdgd/historico'
            },
            { name: 'Logs' }
          ]
        },
      }
    ]
  }
];
