import routes from '@/store/modules/routes';

export default [
  {
    path: '/grupos-relatorios',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['GRUPOS_RELATORIOS_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/grupos-relatorios/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['GRUPOS_RELATORIOS_HISTORICO'] }]
        }
      },
      {
        name: routes.state.appRoutes['GRUPOS_RELATORIOS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/grupos-relatorios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            {
              name: routes.state.appRoutes['GRUPOS_RELATORIOS_HISTORICO'],
              path: '/grupos-relatorios/historico'
            },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['GRUPOS_RELATORIOS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/grupos-relatorios/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            {
              name: routes.state.appRoutes['GRUPOS_RELATORIOS_HISTORICO'],
              path: '/grupos-relatorios/historico'
            },
            { name: 'Editar' }
          ]
        }
      }
    ]
  }
];
