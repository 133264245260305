import routes from '@/store/modules/routes';

export default [
  {
    path: '/dados/',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['VERSOES_DA_BDGD_HISTORICO'],
        path: 'versoes-bdgds/historico',
        component: () => import('@/views/dados/versoes-bdgds/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['DADOS'], path: '/dados' },
            { name: routes.state.appRoutes['VERSOES_DA_BDGD_HISTORICO'] }
          ]
        }
      }
    ]
  }
];
