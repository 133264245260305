import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/entidade-bdgd',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['VISUALIZAR_ENTIDADE'],
        path: 'visualizar/:entidade/:bdgdVersaoId',
        component: () =>
          import('@/views/geracao-bdgd/entidade-bdgd/Visualizar.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD'],
              path: '/geracao-bdgd/atualizacoes-bdgd/historico'
            },
            { name: routes.state.appRoutes['VISUALIZAR_ENTIDADE'] }
          ]
        }
      }
    ]
  }
];
