const state = {
  tipoListaCanvas: { tipo: 'padrão' }
};

const getters = {
  getTipoListaCanvas: (state) => state.tipoListaCanvas
};

const actions = {
  setTipoListaCanvas({ commit }, value) {
    commit('changeTipoListaCanvas', value);
  }
};

const mutations = {
  changeTipoListaCanvas: (state, value) => (state.tipoListaCanvas = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
