import routes from '@/store/modules/routes';

export default [
  {
    path: '/relatorios-modelos',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['RELATORIOS_MODELOS_HISTORICO'],
        path: 'historico',
        component: () =>
          import('@/views/power-ups/relatorios-modelos/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            { name: routes.state.appRoutes['RELATORIOS_MODELOS_HISTORICO'] }
          ]
        },
      },
      {
        name: routes.state.appRoutes['RELATORIOS_MODELOS_ADICIONAR'],
        path: 'edit',
        component: () =>
          import('@/views/power-ups/relatorios-modelos/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            {
              name: routes.state.appRoutes['RELATORIOS_MODELOS_HISTORICO'],
              path: '/relatorios-modelos/historico'
            },
            { name: 'Adicionar' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['RELATORIOS_MODELOS_EDITAR'],
        path: 'edit/:id',
        component: () =>
          import('@/views/power-ups/relatorios-modelos/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['ADMINISTRACAO'], path: '/admin' },
            {
              name: routes.state.appRoutes['RELATORIOS_MODELOS_HISTORICO'],
              path: '/relatorios-modelos/historico'
            },
            { name: 'Editar' }
          ]
        },
      }
    ]
  }
];
