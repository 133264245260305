const state = {
  canvasConfig: {
    layers: []
  }
};

const getters = {
  getCanvasConfig: (state) => state.canvasConfig,
  getCanvasConfigLayers: (state) => state.canvasConfig.layers
};

const actions = {
  resetaCanvasConfig({ commit }) {
    commit('changeCanvasConfig', {
      layers: []
    });
  },
  setCanvasConfigLayers({ commit }, value) {
    commit('changeCanvasConfigLayers', value);
  }
};

const mutations = {
  changeCanvasConfig: (state, value) => (state.canvasConfig = value),
  changeCanvasConfigLayers: (state, value) =>
    (state.canvasConfig.layers = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
