const state = {
  mapConfig: {
    maxFeatures: 5000
  }
};

const getters = {
  getMapConfig: (state) => state.mapConfig,
  getMapConfigMaxFeatures: (state) => state.mapConfig.maxFeatures
};

export default {
  state,
  getters
};
