const state = {
  tipoListaLayer: { tipo: 'padrão' }
};

const getters = {
  getTipoListaLayer: (state) => state.tipoListaLayer
};

const actions = {
  setTipoListaLayer({ commit }, value) {
    commit('changeTipoListaLayer', value);
  }
};

const mutations = {
  changeTipoListaLayer: (state, value) => (state.tipoListaLayer = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
