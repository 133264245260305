import routes from '@/store/modules/routes';

export default [
  {
    path: '/powerups',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['POWER_UPS'],
        path: '',
        component: () => import('@/views/power-ups/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: routes.state.appRoutes['POWER_UPS'] }]
        }
      }
    ]
  }
];
