const state = {
  // https://leafletjs.com/reference.html#control-layers
  mapFeaturesStyles: {
    color: '#3388ff',
    fillColor: '#3388ff',
    weight: 1.5,
    opacity: 1,
    fillOpacity: 0.2,
    radius: 4
  }
};

const getters = {
  getMapFeaturesStyles: (state) => state.mapFeaturesStyles,
  getMapFeatureColor: (state) => state.mapFeaturesStyles.color,
  getMapFeatureFillColor: (state) => state.mapFeaturesStyles.fillColor,
  getMapFeatureWeight: (state) => state.mapFeaturesStyles.weight,
  getMapFeatureOpacity: (state) => state.mapFeaturesStyles.opacity,
  getMapFeatureFillOpacity: (state) => state.mapFeaturesStyles.fillOpacity,
  getMapFeatureRadius: (state) => state.mapFeaturesStyles.radius
};

export default {
  state,
  getters
};
