import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/validacoes-indqual',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['VALIDACOES_INDQUAL'],
        path: '',
        component: () =>
          import('@/views/geracao-bdgd/validacoes-indqual/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            { name: routes.state.appRoutes['VALIDACOES_INDQUAL'] }
          ]
        },
      },
      {
        name: routes.state.appRoutes['SIMULACOES_INDQUAL_ADICIONAR'],
        path: 'simulacoes/add',
        component: () =>
          import('@/views/geracao-bdgd/validacoes-indqual/simulacoes/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            { name: routes.state.appRoutes['VALIDACOES_INDQUAL'], path: '/geracao-bdgd/validacoes-indqual' },
            { name: 'Nova Simulação' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['VALIDACOES_INDQUAL_PAINEL_UNIFICADO'],
        path: 'painel-unificado',
        component: () =>
          import('@/views/geracao-bdgd/validacoes-indqual/PainelUnificado.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['GERACAO_DA_BDGD'], path: '/geracao-bdgd' },
            {
              name: routes.state.appRoutes['VALIDACOES_INDQUAL'],
              path: '/geracao-bdgd/validacoes-indqual'
            },
            { name: 'Painel Unificado INDQUAL' }
          ]
        },
      }
    ]
  }
];
