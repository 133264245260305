import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/prog-geo-perdas-aneel',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL'],
        path: 'historico',
        component: () => import('@/views/perdas-tecnicas/prog-geo-perdas-aneel/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            { name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL_ADICIONAR'],
        path: 'add',
        component: () => import('@/views/perdas-tecnicas/prog-geo-perdas-aneel/Add.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL'],
              path: '/perdas-tecnicas/prog-geo-perdas-aneel/historico'
            },
            { name: 'Executa MT-BT' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL_LOGS'],
        path: 'logs/:id',
        component: () => import('@/views/perdas-tecnicas/prog-geo-perdas-aneel/Logs.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'], path: '/perdas-tecnicas' },
            {
              name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL'],
              path: '/perdas-tecnicas/prog-geo-perdas-aneel/historico'
            },
            { name: 'Logs' }
          ]
        },
      }
    ]
  }
];