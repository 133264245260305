import Vue from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  email,
  max,
  min,
  min_value,
  numeric,
  required,
  regex
} from 'vee-validate/dist/rules';

extend('email', email);

extend('regex', {
  ...regex,
  message: 'Formato inválido'
});

extend('max', {
  ...max,
  message: 'Comprimento excedido'
});

extend('min', {
  ...min,
  message: 'O valor do campo está inválido'
});

extend('numeric', numeric);

extend('required', {
  ...required,
  message: 'Este campo é obrigatório'
});

extend('required_if', {
  ...required,
  message: 'Este campo é obrigatório'
});

extend('min_value', {
  ...min_value,
  message: 'O valor do campo está inválido'
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
