<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    :id="idElement"
  >
    <v-list-item-icon
      v-if="item.icon"
      class="v-list-item__icon--text"
    >
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-icon
      v-else-if="text"
      class="v-list-item__icon--text"
      :prepend-icon="item.icon"
    >
      {{ computedText }}
    </v-list-item-icon>
    <v-list-item-icon
      v-else-if="item.sigla"
      class="v-list-item__icon--text"
      >{{ item.sigla }}</v-list-item-icon
    >
    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title>{{ item.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from 'vuetify/lib/mixins/themeable';

export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    idElement: {
      type: String,
      default: 'base-item'
    },
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined
      })
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return '';
      let text = '';
      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });
      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? '#' : undefined);
    }
  }
};
</script>
