import routes from '@/store/modules/routes';

export default [
  {
    path: '/mapas/canvas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['CANVAS_HISTORICO'],
        path: 'historico',
        component: () => import('@/views/canvas/Historico.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['CANVAS_HISTORICO'] }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CANVAS_EDITAR'],
        path: 'edit/:id',
        component: () => import('@/views/canvas/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['CANVAS_HISTORICO'], path: '/mapas/canvas/historico' },
            { name: 'Editar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CANVAS_ADICIONAR'],
        path: 'edit',
        component: () => import('@/views/canvas/Edit.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: routes.state.appRoutes['CANVAS_HISTORICO'], path: '/mapas/canvas/historico' },
            { name: 'Adicionar' }
          ]
        }
      },
      {
        name: routes.state.appRoutes['CANVAS_VISUALIZAR_MAPA'],
        path: 'visualizar-mapa/:id',
        component: () => import('@/views/canvas/VisualizarMapa.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: routes.state.appRoutes['MAPAS'], path: '/mapas' },
            { name: 'Mapas Personalizados', path: '/mapas/canvas/historico' },
            { name: 'Visualizar Mapa' }
          ]
        }
      }
    ]
  }
];
